.modalContainer {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .80);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.modalMsg {
  background-color: #fff;
  border-radius: 5px;
  width: 60%;
  height: 50%;
  color: #000;
}

.modalMsgAviso {
  background-color: #fff;
  border-radius: 5px;
  width: 40%;
  height: 40%;
  color: #000;
}

.link-menu {
  font-size: 1.5rem;
  padding: 10px 10px;
  color: #3e3e3f;
  text-decoration: none;
  font-weight: 500;

}

.link-menu:hover {
  color: #1a68b6;
  border-left: 3px solid #1a68b6
}

.modalContainerUsuario {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, .80);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.modalMsgUsuario {
  background-color: #fff;
  border-radius: 5px;
  width: 40%;
  height: 75%;
  color: #000;
}